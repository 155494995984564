@tailwind base;
@tailwind components;
@tailwind utilities;

@import "//cdn-images.mailchimp.com/embedcode/slim-10_7.css";

@bgcolor: #4182e4;
@textcolor: rgb(238, 238, 238);
@highlightcolor: white;

@footerbgcolor: #2b2b40;
@footertextcolor: #666;

@headerTextColor: rgba(255,255,255,0.8);
@headerTextHoverColor: white;


body {
    font-family: 'Noto Sans','Roboto', sans-serif;
    background-color: @bgcolor;
    color: @textcolor;
    text-align: center;
}

img {
    width: auto;
}

a {
    text-decoration: underline;

    &:hover {
        color: @highlightcolor;
    }
}

header {
    
    padding: 20px;

    .logo {
        display: none;
        height: 40px;

        @media (min-width: 500px) {
			display: inline;
		}
    }
   
    a {
        text-decoration: none;
        text-transform: uppercase;
        margin: 0 3px;
        border-bottom: 1px solid transparent;
        color: @headerTextColor;
        transition: .2s ease-in-out;
        padding: 10px;    

        &:hover {
            border-bottom: 1px solid @headerTextHoverColor;
            color: @headerTextHoverColor;    
        }
    }

    .action-button {
        border-width: 1px;
        border-style: solid;
        border-color: rgb(204, 204, 204);
        border-image: initial;
        border-radius: 5px;    

        &:hover {
            color: @bgcolor;
            background: @highlightcolor;
        }
    }

}

.narrow-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 900px;
}

.wide-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1000px;
}

/* 
 * Zoomable images 
 */

/* Enables the full image when checked. */
.zoomable-image input[type=checkbox]:checked ~ .full-image-container {
    display: block;
}

.zoomable-image {
    input[type="checkbox"] {
        display: none;            
    }

    img {
        border-width: 1px;
        border-style: solid;
        border-color: rgb(160, 174, 192);
        border-radius: 2px;
        cursor: zoom-in;
        padding: 2px;
        background-color: #F1F1F1;
    }

    .full-image-container {
        position: relative;
        display: none;

        .full-image {
            margin: 0;
            padding: 0;
            position: fixed;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            overflow: auto;
            cursor: zoom-out;
            background-color: rgba(0, 0, 0, 0.65);
            z-index: 10;

            img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);    
                max-width: 85%;
                padding: 4px;
                max-height: 85%;
                width: auto;
                height: auto;
                cursor: zoom-out;
            }            
        }
    }
}

/* Sections */

.blue {
    p {
        font-size: 1.38em;
        margin-top: 20px;
        font-weight: 300;
        line-height: 1.4em;        
    }

    .logo {
        max-width: 350px;
        margin: 0px auto 25px;    
    }

    .title {
        font-weight: 300;
        font-size: 2em;
        max-width: 600px;
    }

    .action-button {
        margin: 60px 0px;
        padding: 12px;
        text-decoration: none;
        transition: all 0.2s ease-in-out 0s;
        background: rgba(255, 255, 255, 0.15);
        border-radius: 3px;
        font-size: 1.3em;
        border-width: 1.5px;
        border-style: solid;
        border-color: rgba(255, 255, 255, 0.3);
        border-image: initial;

        &:hover {
            background: rgba(255, 255, 255, 0.224);
            }
    }

    .vid-container {
        position: relative;
        padding-bottom: 50%;
        padding-top: 35px;
        height: 0;
        overflow: hidden;
        width: 100%;

        iframe {
            border-top-width: 0px;
            border-right-width: 0px;
            border-bottom-width: 0px;
            border-left-width: 0px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.white {
    background-color: #F1F1F1;
    color: black;

    a {
        color: #4182E4;        

        &:hover {
            color: blue;            
        }
    }

    p {
        font-size: 1.38em;
        margin-top: 20px;
        font-weight: 300;
        line-height: 1.4em;        
    }
    
    blockquote {
    
        p {
            font-style: italic;
            font-size: 1.38em;
            color: #666;
            font-weight: 300;
        }
    
        .author {
            margin-top: .75em;
            color: #666;
            font-weight: bold;
            font-size: .85em;
        }
    }
   
}

.about {

    ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
    }

    li {
        display: list-item;
        list-style-type: disc;
    }

    .profile-image {
        font-family: 'Noto Sans','Roboto', sans-serif;
        width: 150px;
        height: 150px;
        overflow: hidden;
        margin: 25px auto 0 auto;
        position: relative;
        border-radius: 200px;

        img {
            font-family: 'Noto Sans','Roboto', sans-serif;
            position: absolute;
            left: -1000%;
            right: -1000%;
            top: -1000%;
            bottom: -1000%;
            margin: auto;
            width: 180px;
        }
    }
}


/* Email sign up */

@keyframes keyframes {
    0% {
        transform: rotate(0);
    }

    25% {
        transform: rotate(3deg);
    }

    50% {
        transform: rotate(-3deg);
    }

    100% {
        transform: rotate(0);
    }
}

#signup {
    color: black;
}

#signup label {
    cursor: pointer;
}

